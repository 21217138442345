<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-start>
      <v-flex xs12 sm6 lg3 xl3 text-left>
        <v-card rounded="lg" elevation="6" class="bg1" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{storage.bookingCount}}</span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/Admin/bookingList/allPrograms">
                    <span class="analyticsKey">Total Bookings </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 text-center align-self-center>
              <v-img
                src="./../../../assets/iconsets/booking.png"
                height="15vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 xl3 pl-0 pl-sm-4 pt-4 pt-sm-0 text-left>
        <v-card rounded="lg" elevation="6" class="bg2" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{storage.bookigs4tomorrow}}</span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/Admin/bookingList/dateWisePrograms">
                    <span class="analyticsKey"> Bookings for Tommorrow </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex ali xs3 xl4 text-center align-self-center>
              <v-img
                src="./../../../assets/iconsets/currbooking.png"
                height="15vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 xl3 pl-0 pl-lg-4 text-left pt-4 pt-lg-0>
        <v-card rounded="lg" elevation="6" class="bg3" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 lg8 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{storage.paymentCount}} </span>
                </v-flex>
                <v-flex xs12 text-left>
                  
                  <!-- to="/Admin/bookingList/allPrograms" -->
                 <router-link to="/PaymentReport">
                    <span class="analyticsKey"> Total Payments </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 pt-5 text-center align-self-center>
              <v-img
                src="./../../../assets/iconsets/users.png"
                height="6vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 xl3 pl-0 pl-sm-4 text-left pt-4 pt-lg-0>
        <v-card rounded="lg" elevation="6  " class="bg4" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{storage.rescheduleTicketCount}} </span>
                  <!-- <span class="analyticsValue"> {{storage.activeProgramCount}} </span> -->
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/Admin/purchaseReports">
                    <span class="analyticsKey"> Total Reschedules </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 text-center align-self-center>
              <v-img
                src="./../../../assets/iconsets/program.png"
                height="15vh "
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout wrap py-4 justify-start>
      <v-flex xs12 sm6 lg3 xl3 text-left>
        <v-card rounded="lg" elevation="6" class="bg5" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{storage.userCount}}</span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/Admin/UserList">
                    <span class="analyticsKey">Total Users </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 text-center align-self-center>
              <v-img
                src="./../../../assets/iconsets/booking.png"
                height="15vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 xl3 pl-0 pl-sm-4 pt-4 pt-sm-0 text-left>
        <v-card rounded="lg" elevation="6" class="bg6" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{storage.visitors}}</span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/Admin/GuestList">
                    <span class="analyticsKey"> Total Visitors </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex ali xs3 xl4 text-center align-self-center>
              <v-img
                src="./../../../assets/iconsets/currbooking.png"
                height="15vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 xl3 pl-0 pl-lg-4 text-left pt-4 pt-lg-0>
        <v-card rounded="lg" elevation="6" class="bg7" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 lg8 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{storage.cancelledBookings}} </span>
                </v-flex>
                <v-flex xs12 text-left>
                  
                  <!-- to="/Admin/bookingList/allPrograms" -->
                 <router-link to="/Admin/cancelledList">
                    <span class="analyticsKey"> Total Cancelled </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 pt-5 text-center align-self-center>
              <v-img
                src="./../../../assets/iconsets/users.png"
                height="6vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 xl3 pl-0 pl-sm-4 text-left pt-4 pt-lg-0>
        <v-card rounded="lg" elevation="6  " class="bg8" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{storage.reservationCount}} </span>
                  <!-- <span class="analyticsValue"> {{storage.activeProgramCount}} </span> -->
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/Admin/ReservationList/dateWisePrograms">
                    <span class="analyticsKey"> Total Reservations </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 text-center align-self-center>
              <v-img
                src="./../../../assets/iconsets/program.png"
                height="15vh "
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
    };
  },
};
</script>
<style scoped>
.analyticsKey {
  font-family: poppinsregular;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 1px;
}
.analyticsValue {
  font-family: poppinsmedium;
  font-size: 25px;
  color: #ffffff;
}
.bg1 {
  background-image: linear-gradient(to right, #00f2fe 0%, #4facfe 100%);
}
.bg2 {
  background-image: linear-gradient(to right, #f093fb 0%, #f5576c 100%);
}
.bg3 {
  background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
}
.bg4 {
  background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
}

.bg5 {
  background-image: linear-gradient(to right, #fe0000ce 0%, #e9a590 100%);
}
.bg6 {
  background-image: linear-gradient(to right, #fb93aa 0%, #b445e7 100%);
}
.bg7 {
  background-image: linear-gradient(to right, #436fe9 0%, #e681f3 100%);
}
.bg8 {
  background-image: linear-gradient(to right, #f570fa 0%, #fe4069 100%);
}
</style>